import './styles/index.css';
import { createRoot } from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from "./App";
import View from "./routes/view";
import Seed from "./routes/seed";
import Tokens from "./routes/tokens";
import TokenView from "./routes/tokenview";
import Home from './routes/home';
import EditView from './routes/editview';

const container = document.getElementById('app');
const root = createRoot(container);
window.Buffer = window.Buffer || require("buffer").Buffer;

root.render(
  <BrowserRouter>
     <Routes>
      <Route path="/" element={<App />} >
        <Route path="/" element={
          <main>
            <Home />
          </main>
        }/>
{/*}
         <Route path="/tokens" element={<Tokens />}>
          <Route
              index
              element={
                <main style={{ padding: "1rem" }}>
                  <p>Select a token</p>
                </main>
              }
            />
          <Route path=":token" element={<TokenView  />} />
        </Route>
            */}        
        <Route path="/*" element={
          <main style={{ padding: "1rem" }}>
            <Home />
          </main>
        }/>
        <Route path="/view" element={<View />}>
          <Route path=":token" element={<View />} />
        </Route>
        <Route path="/edit" element={<EditView />}>
          <Route path=":token" element={<EditView />} />
        </Route>
      </Route>
      <Route path="/seed" element={<Seed />}>
          <Route path=":seed" element={<Seed />} />
        </Route>
    </Routes>
  </BrowserRouter>
);