import { getSeeds } from "../seeds";
import { useContractRead } from 'wagmi';
import {useState,useContext,useEffect} from 'react';
import { PxContext } from '../App';
import ShadowFlora from '../data/ShadowFlora.json';
import { SeedCard } from './seedcard';

export const SeedBed = (props: Props)=>{
    const {address} = useContext(PxContext);
    const [count,setCount] = useState(0);
    const [tokens,setTokens] = useState([]);
    const [cards,setCards] = useState([]);
    const [startToken,setStartToken] = useState(0);
    const {data: mintcounter} = useContractRead({
        addressOrName: address,
        contractInterface: ShadowFlora.abi,
        },'lastMintedId',{
        onSuccess(data) {
            const counter = parseInt(data);
            setCount(counter);
            setStartToken(data-3);
            loadTokens(counter);
        }
        },
    );

    const loadTokens = (count)=>{
       let toke = getSeeds();
       toke = toke.filter((t,i,arr)=>{
           return i < count;
       });
       setTokens(toke);
    }

    const saveCards = (t)=>{
       const saved = t.map( viewer => (
            <SeedCard id={viewer.id} key = { Date.now() + viewer.id } />
            )
        )
        setCards(saved);
    }

    const advanceStart = ()=>{
        setStartToken((startToken+3)%count);
    }

    useEffect(()=>{
        loadTokens(count);
    },[count]
    );

    useEffect(()=>{
        saveCards(tokens);
    }
    ,[tokens])

    return (
        <div className="garden-box" style={{ width:'100%', height:'100%'}}>
        { 
            cards.slice(startToken,startToken+3) 
        }
        <div onClick={advanceStart} className="seed-button"></div>
        </div>
    );
}