export default function Footer(){
return(
    <div className="footer-text">
    <p>Shadow Flora is an Art NFT project conceived, developed, and created independently by <a href="https://linktr.ee/pixlpa">Andrew Benson (@pixlpa)</a> -
        creator of <a href="https://grinders-nft.xyz">Grinders</a>, <a href="https://opensea.io/collection/wavelet-pools">Wavelet Pools</a>, <a href="https://foundation.app/collection/transientmaps">Transient Maps</a>,
        and 1/1 artworks on <a href="https://foundation.app/@pixlpa?tab=created">Foundation</a>.<br />
          <a href="https://pixlpa.com">Andrew Benson</a> is Los Angeles based visual artist combining graphics technology, code, and experimental generative processes.
         </p>
    </div>
)
}