import {useContext} from 'react'
import TokenView from "./tokenview";
import Technical from "../components/technical";
import Footer from "../components/footer";
import { RainbowSign } from "../components/rainbowsign";
import "../styles/App.css";
import { SeedBed } from "../components/seedbed";
import { PxContext } from "../App";

export default function Home() {
  const {address,opensea,zora,looksrare} = useContext(PxContext);
  return (
    <div>
    <h1 className="header" style={{padding:'1rem'}}>Shadow Flora</h1>
    <div style={{display:'flex', flexDirection:'column'}}>
      <div className="flex-wrapper" style={{marginBottom:'40px',padding:'1%'}}>
        <div className="sub-text" style={{maxWidth:'700px',margin:'20px'}}>
          The <b>Shadow Flora</b> are a collection of 250 unique living works of generative image feedback art created by <a href="https://linktr.ee/pixlpa">Andrew Benson</a>. 
          Each begins as a color seed that grows from the shadows into a luminous composition that seeks an active equilibrium state.
        <div className="collection-links">
          <div><a href={`https://etherscan.io/address/${address}`}>View on Etherscan</a></div> 
          <div>❖</div>    
          <div><a href={opensea}>OpenSea</a></div> 
          <div>❖</div>
          <div><a href={zora}>Zora</a></div>
          <div>❖</div>
          <div><a href={looksrare}>LooksRare</a></div>
        </div>
        </div>
        <RainbowSign />
      </div>
      <TokenView token='0x8c86c87b6b7a3af23224c3b5cb8320f6503a6105102cdea9944750af59b1de5c' />
      <SeedBed />
      <Technical />
      <Footer />
      </div>
      </div>
    );
}