import {React, useEffect,useContext,useState} from 'react';
import { useParams} from "react-router-dom";
import { getToken } from '../seeds';
import {initGL,initSlabs,initImages,removeGL} from '../scripts/pxGLstart';
import { useContractRead } from 'wagmi';
import { PxContext } from '../App';
import ShadowFlora from '../data/ShadowFlora.json';
import FullView from '../components/fullview';

const View = (props)=> {
  const {address} = useContext(PxContext);
  const [count,setCount] = useState(0);
  let params = useParams();
  let tokenid = getToken(parseInt(params.token, 10));
  let inactiveTimer;
  const seed = parseInt(tokenid.slice(0, 16), 16);
  const {data: mintcounter} = useContractRead({
    addressOrName: address,
    contractInterface: ShadowFlora.abi,
},'lastMintedId',{
  onSuccess(data) {
    setCount(parseInt(data));
  }
},
  );

  const hideInactive = ()=>{
    const elem = document.getElementsByClassName('fullscreen-actions')[0];
    window.clearTimeout(inactiveTimer);
    if(elem){
      elem.style.opacity = 1;
      inactiveTimer = window.setTimeout(()=>{
        elem.style.opacity = 0;
      },2000);
    }
  }

  useEffect(()=>{
    if(count >= parseInt(params.token, 10)){
      initGL(seed);
      initSlabs();
      initImages();
      hideInactive();
    }
    return () => {
      removeGL();
    };
  },[seed,count]);
  
  return (
    <main>
    <FullView backgroundColor="#FFFFFF">
    <div style={{ 
      position: 'absolute', 
      top: 0, left: 0, width: '100vw', height: '100vh',
      display: 'flex', alignItems: 'center', justifyContent: 'center',
      backgroundColor: '#000'}} onClick={hideInactive} onMouseMove={hideInactive} >
      <canvas id="c" />
    </div>
    </FullView>
    </main>
  );
};

  export default View;