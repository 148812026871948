import {React, useEffect} from 'react';
//import { getToken } from '../seeds';
import {initGL,initSlabs,initImages,removeGL} from '../scripts/pxGLstart';

const TokenView = (props)=> {
  let params = props;
  let tokenid = params.token;
  const seed = parseInt(tokenid.slice(0, 16), 16);
  useEffect(()=>{
    initGL(seed);
    initSlabs();
    initImages();
    //animate();
    return () => {
      removeGL();
    };
  },[seed]);
  
  return (
    <main style={{  
      width: '100%', height: '100%',
      display: 'flex', alignItems: 'center', justifyContent: 'center',
      backgroundColor: '#000'  }}>
      <canvas id="c"></canvas>
    </main>
  );
};

  export default TokenView;