import React from "react";

const Technical = (props)=>{
    return ( 
    <div className="technical">
    <h2>How Shadow Flora Grow</h2>
    <p>
        Shadow Flora NFTs show a still image captured from a developed Shadow Flora simulation, paired with a working
        WebGL-driven simulation linked from the "animation_url" field of the metadata. To see your NFT in its true form, visit the 
        site linked from the description of the token itself. <br /><br />
        Each of the flora are formed from a small seed of generated colors that, through an image feedback process, develop into an organic form.
        This feedback process comprises two parallel feedback loops that generate a mask/distortion image and the final color image 
        respectively. The motion of colors from frame to frame is determined by each pixel comparing itself to its surroundings and following the general
        slope of the surrounding color gradient.<br /><br />
        The feedback-generated mask image uses the red channel to determine whether each pixel moves toward
        or away from the brighter colors around it. The green and blue channels of the mask image define a low-resolution distortion map that also influences the
        movement.<br /><br />
        All of this is generated using a series of fragment shaders that capture to a <b>framebuffer</b> to store the results of the process. Because this process 
        is based on live-rendered process, each time the simulation is loaded will be very similar, but may evolve in slightly unique ways. The settings of the Shadow Flora 
        simulations are tuned to dynamically reach a state of relative equilibrium that begins to resemble an animated loop or a constantly radiating form.
    </p>
    </div>
    );
}

export default Technical;