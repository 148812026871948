import { pxShader,pxSlab,pxFBO,pxProgram } from './pxslabs';
import shades from './pxshaders';

let c = document.getElementById('c');
let gl,glsize;
let basevs,basefs,feedback,heightfs,heightflow,finalfs,blackfb,blackflow,blackfs;
let ppgm,finalpgm,noisetex,blacktex,FLOWSTATE,BLACKSTATE;
let R;

//---setup random class
export class Random {
    constructor(seed) {
        this.seed = seed;
    }
    random_dec() {
        /* Algorithm "xor" from p. 4 of Marsaglia, "Xorshift RNGs" */
        this.seed ^= this.seed << 13
        this.seed ^= this.seed >> 17
        this.seed ^= this.seed << 5
        return ((this.seed < 0 ? ~this.seed + 1 : this.seed) % 1000) / 1000
    }
    num(a, b) {
        return a+(b-a)*this.random_dec()
    }
    int(a, b) {
        return Math.floor(this.num(a, b+1))
    }
}

function initGL(seed){
	let secretnumber = [];
	c = document.getElementById('c');
	cancelAllAnimFrames();
	const iw = Math.min(window.innerWidth,window.innerHeight);
	glsize = 1000;
	c.width = iw;
	c.height = iw;
	c.aspect = 1;
	gl = c.getContext('webgl');
	gl.getExtension('OES_texture_float');
	gl.blendFunc(gl.ONE,gl.ONE_MINUS_SRC_ALPHA);
	gl.disable(gl.BLEND);
	gl.disable(gl.DEPTH_TEST);
	gl.clearColor(0.,0.,0.,1.);
	gl.viewport(0,0,c.width,c.height);
	gl.clear(gl.COLOR_BUFFER_BIT);
	R = null;
	R = new Random(seed);
	FLOWSTATE = {
		LC: {r:0.35,g:0.25,b:0.3},
		offset: 11/1000,
		bright: 1.0057,
		dark:	0.003,
		power:	R.num(11,13.25)/1000,
		shift:	4/1000,
		warp:	3.9/1000,
		mixin:	0.024,
		//mixinsat: 0.4,
		mixinsat: R.num(0.5,0.9),
		aspect: 1,
		zoom: 1.35
	};
	FLOWSTATE.calc = function(){
		gl.useProgram(heightflow.pgm);
		gl.uniform4f(gl.getUniformLocation(heightflow.pgm,"LC"),this.LC.r,this.LC.g,this.LC.b,0);
		gl.uniform1f(gl.getUniformLocation(heightflow.pgm,"offset"),this.offset);
		gl.uniform1f(gl.getUniformLocation(heightflow.pgm,"bright"),this.bright);
		gl.uniform1f(gl.getUniformLocation(heightflow.pgm,"dark"),this.dark);
		gl.uniform1f(gl.getUniformLocation(heightflow.pgm,"power"),this.power);
		gl.uniform2f(gl.getUniformLocation(heightflow.pgm,"shift"),0,this.shift);
		gl.uniform1f(gl.getUniformLocation(heightflow.pgm,"warp"),this.warp);
		gl.uniform1f(gl.getUniformLocation(heightflow.pgm,"mixin"),this.mixin);
		gl.uniform1f(gl.getUniformLocation(heightflow.pgm,"mixinsat"),this.mixinsat);
		gl.uniform1f(gl.getUniformLocation(heightflow.pgm,"aspect"),this.aspect);
		gl.uniform1f(gl.getUniformLocation(heightflow.pgm,"zoom"),this.zoom);
	};
	secretnumber = [R.num(13,19),R.num(0.5,0.9)];
	BLACKSTATE = {
		LC: {r:0.25,g:0.3,b:0.4},
		offset: 6/1000,
		bright: 1.028,
		dark:	0.015,
		power:	8/1000,
		shift:	3/1000,
		warp:	3.9/1000,
		mixin:	0.04,
		mixinsat: 0.,
		aspect: 1,
		zoom: 1
	};
	BLACKSTATE.calc = function(){
		gl.useProgram(blackflow.pgm);
		gl.uniform4f(gl.getUniformLocation(blackflow.pgm,"LC"),this.LC.r,this.LC.g,this.LC.b,0);
		gl.uniform1f(gl.getUniformLocation(blackflow.pgm,"offset"),this.offset);
		gl.uniform1f(gl.getUniformLocation(blackflow.pgm,"bright"),this.bright);
		gl.uniform1f(gl.getUniformLocation(blackflow.pgm,"dark"),this.dark);
		gl.uniform1f(gl.getUniformLocation(blackflow.pgm,"power"),this.power);
		gl.uniform2f(gl.getUniformLocation(blackflow.pgm,"shift"),0,this.shift);
		//gl.uniform1f(gl.getUniformLocation(blackflow.pgm,"warp"),this.warp);
		gl.uniform1f(gl.getUniformLocation(blackflow.pgm,"mixin"),this.mixin);
		gl.uniform1f(gl.getUniformLocation(blackflow.pgm,"mixinsat"),this.mixinsat);
		gl.uniform1f(gl.getUniformLocation(blackflow.pgm,"aspect"),this.aspect);
		//gl.uniform1f(gl.getUniformLocation(blackflow.pgm,"zoom"),this.zoom);
	};
}

export function initSlabs(){
	basevs = pxShader(shades.basevs,gl.VERTEX_SHADER);
	basefs = pxShader(shades.basefs,gl.FRAGMENT_SHADER);
	finalfs = pxShader(shades.finalfs,gl.FRAGMENT_SHADER);
	heightfs = pxShader(shades.heightflow,gl.FRAGMENT_SHADER);
	blackfs = pxShader(shades.heightflow2,gl.FRAGMENT_SHADER);
	feedback = new pxFBO();
	feedback.allocate3(glsize,glsize);
	blackfb = new pxFBO();
	blackfb.allocate3(glsize,glsize);
	heightflow = new pxSlab(basevs,heightfs);
	heightflow.allocate3(glsize,glsize);
	blackflow = new pxSlab(basevs,blackfs);
	blackflow.allocate3(glsize,glsize);
	ppgm = pxProgram(basevs,basefs);
	finalpgm = pxProgram(basevs,finalfs);	
	FLOWSTATE.calc();
	BLACKSTATE.calc();
}

export function initImages(){		
	createNoise();
	createBlack();
	window.setTimeout(animate,100);			
}

export function animate() {
	window.requestAnimFrame( animate );
	gl.clear(gl.COLOR_BUFFER_BIT);

	blackflow.start();
	gl.viewport(0,0,glsize,glsize);
	gl.clear(gl.COLOR_BUFFER_BIT);
	blackfb.draw2(blackflow.pgm,blacktex);

	blackfb.start();
	gl.viewport(0,0,glsize,glsize);
	gl.clear(gl.COLOR_BUFFER_BIT);
	blackflow.draw(ppgm);

	heightflow.start();
	gl.viewport(0,0,glsize,glsize);
	gl.clear(gl.COLOR_BUFFER_BIT);
	feedback.draw3(heightflow.pgm,noisetex,blackflow.texture);

	feedback.start();
	gl.viewport(0,0,glsize,glsize);
	gl.clear(gl.COLOR_BUFFER_BIT);
	heightflow.draw(ppgm);

	gl.bindFramebuffer(gl.FRAMEBUFFER, null);
	gl.viewport(0,0,c.width,c.height);
	heightflow.draw(finalpgm);
}

function createNoise(){
	noisetex = gl.createTexture();
	noisetex.tmp = document.createElement("canvas");
	noisetex.ctx = noisetex.tmp.getContext('2d');
   	var cc = noisetex.ctx;
   	var x, y;
	const seedsize = R.int(5,7);
	if (seedsize === 5){
		FLOWSTATE.power *= 1.25;
		FLOWSTATE.zoom *= 0.9;
	}
 	noisetex.tmp.width = seedsize;
   	noisetex.tmp.height = seedsize;
	cc.fillStyle = "#000";
	cc.fillRect(0,0,7,7);
   	for ( x = 0; x < noisetex.tmp.width; x++ ) {
      	for ( y = 0; y < noisetex.tmp.height; y++ ) {
			if(x > 0 && x < (seedsize-1) && y>0 && y<(seedsize-1)){
				cc.fillStyle = "rgba(" + R.int(0,255) + "," + R.int(0,255) + "," + R.int(0,255) + "," + 1 + ")";
				if (R.int(0,20)>17) cc.fillStyle = "#000";
				cc.fillRect(x, y, 1, 1);
			}
      	}
   	}
   	gl.bindTexture(gl.TEXTURE_2D, noisetex);
	gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
  	gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
  	gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
  	gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
   	gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, noisetex.tmp);
	FLOWSTATE.calc();
}

function createBlack(){
	blacktex = gl.createTexture();
	blacktex.tmp = document.createElement("canvas");
	blacktex.ctx = blacktex.tmp.getContext('2d');
   	var cc = blacktex.ctx;
   	var x, y,number;
 	blacktex.tmp.width = 9;
   	blacktex.tmp.height = 9;
	cc.fillStyle = "#000";
	cc.fillRect(0,0,15,15);
   	for ( x = 0; x < blacktex.tmp.width; x++ ) {
      	for ( y = 0; y < blacktex.tmp.height; y++ ) {
			number = R.int(0,5)*51;
			cc.fillStyle = "rgba(" + number + "," + R.int(0,255) + "," + R.int(0,255) + "," + 1 + ")";
			cc.fillRect(x, y, 1, 1);

      	}
   	}
   	gl.bindTexture(gl.TEXTURE_2D, blacktex);
	gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
  	gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
  	gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
  	gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
   	gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, blacktex.tmp);
	BLACKSTATE.calc();
}

function windowResize() {
	var gsize = Math.min(window.innerWidth,window.innerHeight);
	c.width  = gsize;
	c.height = gsize;
};
window.addEventListener('resize', windowResize);

window.requestAnimFrame = (function() {
	return window.requestAnimationFrame ||
		   window.webkitRequestAnimationFrame ||
		   window.mozRequestAnimationFrame ||
		   window.oRequestAnimationFrame ||
		   window.msRequestAnimationFrame ||
		   function(/* function FrameRequestCallback */ callback, /* DOMElement Element */ element) {
			 return window.setTimeout(callback, 1000/60);
		   };
  })();
  
window.cancelAnimFrame = window.cancelAnimationFrame || window.mozCancelAnimationFrame;

function cancelAllAnimFrames(){
	var id = window.requestAnimFrame(function(){});
	while(id--){
	window.cancelAnimFrame(id);
	}
}

export function removeGL(){
	cancelAllAnimFrames();
}

window.getDataImage = ()=>{
	c.width = glsize;
	c.height = glsize;
	gl.viewport(0,0,glsize,glsize);
	FLOWSTATE.calc();
	BLACKSTATE.calc();
	gl.clear(gl.COLOR_BUFFER_BIT);

	blackflow.start();
	gl.clear(gl.COLOR_BUFFER_BIT);
	blackfb.draw2(blackflow.pgm,blacktex);

	blackfb.start();
	gl.clear(gl.COLOR_BUFFER_BIT);
	blackflow.draw(ppgm);
	
	heightflow.start();
	gl.clear(gl.COLOR_BUFFER_BIT);
	feedback.draw3(heightflow.pgm,noisetex,blackflow.texture);

	feedback.start();
	gl.clear(gl.COLOR_BUFFER_BIT);
	heightflow.draw(ppgm);
	
	gl.bindFramebuffer(gl.FRAMEBUFFER, null);
	heightflow.draw(finalpgm);
	const data = {
		"image": c.toDataURL()
	};
	windowResize();
	return data;
}

export {c,gl,initGL};