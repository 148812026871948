import { useEnsName,useContractRead } from "wagmi";
import { Link } from "react-router-dom";
import { getSeeds } from "../seeds";
import {useState,useContext,useEffect} from 'react';
import { PxContext } from '../App';
import ShadowFlora from '../data/ShadowFlora.json';

export const SeedCard = (props)=>{
    const baseIMG  = 'https://pixlpa.com/qa/shadowflora/images';
    const {address} = useContext(PxContext);
    const [owner,setOwner] = useState('');
    const [name,setName] = useState('');

    const ownership = useContractRead(
        {
            addressOrName: address,
            contractInterface: ShadowFlora.abi,
        },
        'ownerOf',
        {
            args: props.id,
            onSuccess(data) {
                setOwner(data)
            },
        },
    )

    return(
    <Link
        key= { Date.now() + props.id }
        style={{ }}
        to={`/view/${props.id}`}
        className="seed-card-link"
    >
    <div className="seed-card" style={{ backgroundImage:`url(${baseIMG}/${props.id}.png)`}}>

        <div className="seed-card-number">
        {props.id}
        </div>
        <div className="seed-card-name"><EnsName address={owner}/></div>
    </div>
    </Link>
   )
}

const EnsName = (props)=>{
    const {address} = props;
    const {data:ensName} = useEnsName(
        {
            address: address,
        }
     )
     const shortaddress = address.substring(0,10)+"…";
     return (ensName? ensName : shortaddress);
}