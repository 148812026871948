import React from 'react';
import PropTypes from 'prop-types';

/**
 * SVG Slider Component for SVG-based visualizations
 */
export default class MaxSlider extends React.Component {
  constructor(props) {
    super(props);
    this.i = 0;
    this._box = null;
    this._svgbox = null;
    this._slider = null;
    this._label = '';
    this.state = {active:false, value: 0.5};
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);
  }
  componentDidMount() {
    if (window.PointerEvent){
      this._box.addEventListener('pointerdown',this.handleMouseDown);
      this._box.addEventListener('touchmove',this._preventDefault);
    }
    else{
       this._box.addEventListener('mousedown',this.handleMouseDown);
       this._box.addEventListener('touchstart',this.handleMouseDown);
    }
  }
  _preventDefault = (e) => {
    e.preventDefault();
  }

  sendStateToParent() {
    this.props.callback(this.state);
  }

  _calcValues(inX,inY){
    const cRect = this._svgbox.getBoundingClientRect();
    const x = inX - cRect.left - this.props.margin;
    const y = inY - cRect.top - this.props.margin;
    const val = Math.min(1,Math.max(0,this.props.vertical? 1-(y/(this.props.height-this.props.margin*2)): x/(this.props.width-this.props.margin*2)));
    this.setState((state, props) => ({
      value: val
    }));
  }

  handleMouseDown(e){
    e.preventDefault();
    e.preventDefault();
    let evt = e;
    if (e.type === "touchstart"){
      evt = e.touches[0];
    }

    if(window.PointerEvent){
      window.addEventListener("pointerup", this.handleMouseUp);
      this._box.addEventListener("pointermove", this.handleMouseMove);
    }
    else {
      window.addEventListener("mouseup", this.handleMouseUp);
      this._box.addEventListener("mousemove", this.handleMouseMove);
      if (e.type==="touchstart"){
        this._box.addEventListener("touchmove", this.handleMouseMove);
        this._box.addEventListener("touchend", this.handleMouseUp);
      }
    }
    this._calcValues(evt.clientX,evt.clientY);
    this.setState((state, props) => ({
      active: true
    }));
    if(this.props.callback) this.sendStateToParent();
  }

  handleMouseMove(e){
    e.preventDefault();
    e.stopPropagation();
    let evt = e;
    if (e.type === "touchmove"){
      evt = e.touches[0];
    }
    this._calcValues(evt.clientX,evt.clientY);
    if(this.props.callback) this.sendStateToParent();
  }

  handleMouseUp(e){
    e.preventDefault();  
    if(window.PointerEvent){
      this._box.removeEventListener("pointermove", this.handleMouseMove);
      window.removeEventListener("pointerup", this.handleMouseUp);
    }
    else{
       this._box.removeEventListener("mousemove", this.handleMouseMove);
       window.removeEventListener("mouseup", this.handleMouseUp);
       if (e.type === "touchend"){
        this._box.removeEventListener("touchmove", this.handleMouseMove);
        this._box.removeEventListener("touchend", this.handleMouseUp);
      }
    }
    let evt = e;
    if (e.type === "touchmove"){
      evt = e.touches[0];
    }
    this._calcValues(evt.clientX,evt.clientY);
    this.setState((state, props) => ({
      active: false
    }));
    if(this.props.callback) this.sendStateToParent();
  }

  render() {
    return (
      <g ref={ el =>this._box=el} transform={'translate('+ this.props.x +','+this.props.y+')'}>
        <rect ref={el => this._svgbox = el } width={this.props.width} height={this.props.height} y="0" style={{ fill: '#222', stroke: 'none'}}/>
        <rect style={{ fill: '#333', stroke: 'none'}} x={this.props.vertical?0:this.props.margin} y={this.props.vertical?this.props.margin:0} width={this.props.vertical?this.props.width:(this.props.width-this.props.margin*2)} height={this.props.vertical?(this.props.height-this.props.margin*2):this.props.height} />
        <rect ref={el=>this._slider = el} style={{ fill: this.props.color, stroke: 'none'}} x={this.props.vertical?0:this.props.margin} y={this.props.vertical?(1-this.state.value)*(this.props.height-this.props.margin*2)+this.props.margin:0} width={this.props.vertical?this.props.width:this.state.value*(this.props.width-this.props.margin*2)} height={this.props.vertical?this.state.value*(this.props.height-this.props.margin*2):this.props.height} />
        <text ref={el => this._label = el } y="-10" style={{ fill: '#bff',fontFamily:'sans-serif',fontSize:'15px'}}>{this.props.label}</text>
      </g>
    );
  }
}

MaxSlider.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  vertical: PropTypes.bool,
  color: PropTypes.string,
  margin: PropTypes.number,
  label: PropTypes.string,
  callback: PropTypes.func
};

MaxSlider.defaultProps = {
  x: 0,
  y: 0,
  width: 20,
  height: 140,
  vertical: true,
  color: '#cee',
  label: '',
  margin: 10,
  callback: null
};