import { Outlet } from "react-router-dom";
import {useEffect,createContext} from 'react';
import './styles/App.css';
import '@rainbow-me/rainbowkit/styles.css';
import {
  apiProvider,
  configureChains,
  getDefaultWallets,
  RainbowKitProvider,
  darkTheme
} from '@rainbow-me/rainbowkit';
import { chain, createClient, WagmiProvider } from 'wagmi';

export const PxContext = createContext();

export default function App(){
  const { provider, chains } = configureChains(
    [chain.mainnet],
    [apiProvider.infura("19a7e96d2806403b8d13322e77595be7"), apiProvider.fallback()]
  );
  const { connectors } = getDefaultWallets({
    appName: 'Shadow Flora',
    chains,
  });
  
  const wagmiClient = createClient({
    autoConnect: true,
    connectors,
    provider
  });
  const basicInfo = {
    address:"0x5d1084EBC5C2E352071AB46ff6fB035d44D69aDE",
    opensea:"https://opensea.io/collection/shadow-flora",
    zora:"https://zora.co/collections/0x5d1084EBC5C2E352071AB46ff6fB035d44D69aDE",
    looksrare:"https://looksrare.org/collections/0x5d1084EBC5C2E352071AB46ff6fB035d44D69aDE"
  }

  useEffect(()=>{
  },[]);

  return (
    <div className="App">
      <WagmiProvider client={wagmiClient}>
        <RainbowKitProvider chains={chains}  coolMode={false} theme={darkTheme({
          accentColor: "#fe6e77",
          borderRadius: 'small',
        })} >
          <PxContext.Provider value={basicInfo}>
            <Outlet />
          </PxContext.Provider>
        </RainbowKitProvider>
      </WagmiProvider>
    </div>
  );
}