import {React, useEffect,useContext,useState,createContext} from 'react';
import { useParams} from "react-router-dom";
import { getToken } from '../seeds';
import {initGL,initSlabs,initImages,removeGL} from '../scripts/pxGLstart-editor';
import { useContractRead } from 'wagmi';
import { PxContext } from '../App';
import ShadowFlora from '../data/ShadowFlora.json';
import FullView from '../components/fullview';
import MaxSlider from '../components/svg/maxslider';

export const EditContext = createContext();

const EditView = (props)=> {
  const {address} = useContext(PxContext);
  const [count,setCount] = useState(0);
  const [editSettings, setEditSettings] = useState({});
  const [flow,setFlow] = useState({});
  let params = useParams();
  let tokenid = getToken(parseInt(params.token, 10));
  let inactiveTimer;
  const seed = parseInt(tokenid.slice(0, 16), 16);
  const {data: mintcounter} = useContractRead({
    addressOrName: address,
    contractInterface: ShadowFlora.abi,
},'lastMintedId',{
  onSuccess(data) {
    setCount(parseInt(data));
  }
},
  );

  const hideInactive = ()=>{
    const elem = document.getElementsByClassName('fullscreen-actions')[0];
    const elem2 = document.getElementsByClassName('fullscreen-actions')[1];
    window.clearTimeout(inactiveTimer);
    if(elem){
      elem.style.opacity = 1;
      elem2.style.opacity = 1;
      inactiveTimer = window.setTimeout(()=>{
        elem.style.opacity = 0;
        elem2.style.opacity = 0;
      },2000);
    }
  }

  const setRenderObjects = (f)=>{
    setFlow(f);
    setEditSettings(f);
  }

  const setRender = (value)=>{
    flow.setValues(value);
    setEditSettings(value);
  }

  const powerCallback = (value)=>{
    const oldState = editSettings;
    oldState.power = (value.value-0.5)/15;
    hideInactive();
    setRender(oldState);
  }

  const satCallback = (value) => {
    const oldState = editSettings;
    oldState.mixinsat = value.value*1.5;
    hideInactive();
    setRender(oldState);
  }

  const zoomCallback = (value) => {
    const oldState = editSettings;
    oldState.zoom = 0.5/(value.value+0.1);
    hideInactive();
    setRender(oldState);
  }

  useEffect(()=>{
    if(count >= parseInt(params.token, 10)){
      initGL(seed,setRenderObjects);
      initSlabs();
      initImages();
      hideInactive();
    }
    return () => {
      removeGL();
    };
  },[seed,count]);
  
  return (
    <main>
    <FullView backgroundColor="#FFFFFF">
    <div style={{ 
      position: 'absolute', 
      top: 0, left: 0, width: '100vw', height: '100vh',
      display: 'flex', alignItems: 'center', justifyContent: 'center',
      backgroundColor: '#000'}} onClick={hideInactive} onMouseMove={hideInactive} >
      <canvas id="c" />
    </div>
    <div className="fullscreen-actions" style={{position: "absolute",top: 50}} onClick={hideInactive} onMouseMove={hideInactive}>
        <svg width="400" height="400">
            <g>
                <MaxSlider x={0} y={50} width={250} height={30} vertical={false} callback={powerCallback} label='power' color='#fb4050' />
                <MaxSlider x={0} y={110} width={250} height={30} vertical={false} callback={satCallback} label='saturate' color='#40fb50' />
                <MaxSlider x={0} y={170} width={250} height={30} vertical={false} callback={zoomCallback} label='seed color' color='#40ebfd' />
            </g>     
        </svg>
      </div>
    </FullView>
    </main>
  );
};

  export default EditView;