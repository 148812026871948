const shades = {
	simplefs:
    	"precision mediump float;\n\
    	varying vec4 vColor;\n\
    	varying vec2 tc;\n\
		uniform sampler2D tex0;\n\
		uniform sampler2D tex1;\n\
		uniform float usetex;\n\
		\n\
    	void main(void) {\n\
    		vec4 tt = texture2D(tex0,tc);\n\
        	gl_FragColor = vec4(mix(vec3(1.),tt.rgb,usetex)*vColor.rgb*vec3(tt.a*vColor.a),vColor.a*tt.a);\n\
    	}",
	simplevs:
	    "attribute vec3 pos;\n\
	    attribute vec4 color;\n\
	    attribute vec2 texcoord;\n\
	    \n\
	    uniform vec2 tscale;\n\
	    uniform vec2 toffset;\n\
	    uniform vec2 pscale;\n\
	    uniform vec2 ptranslate;\n\
	    uniform vec4 pcolor;\n\
		\n\
    	varying vec4 vColor;\n\
    	varying vec2 tc;\n\
		\n\
    	void main(void) {\n\
        	gl_Position = vec4(pos*vec3(pscale.xy,1.)+vec3(ptranslate.xy,0.),1.);\n\
        	vColor = color*pcolor;\n\
        	vec2 ttt = ((texcoord*2.-vec2(1.))*tscale+toffset)*0.5+vec2(0.5);\n\
        	tc= ttt;\n\
    	}",
     basevs:
     	"attribute vec3 pos;\n\
     	attribute vec4 color;\n\
     	attribute vec2 texcoord;\n\
     	varying vec4 vColor;\n\
     	varying vec2 tc;\n\
     	\n\
     	void main(void){\n\
     		gl_Position = vec4(pos,1);\n\
     		tc = texcoord;\n\
     		vColor = color;\n\
     	}",
     basefs:
    	"precision mediump float;\n\
    	varying vec4 vColor;\n\
    	varying vec2 tc;\n\
		uniform sampler2D tex0;\n\
		uniform sampler2D tex1;\n\
		\n\
    	void main(void) {\n\
        	gl_FragColor = texture2D(tex0,tc);\n\
    	}",
		finalfs:
    	"precision mediump float;\n\
    	varying vec2 tc;\n\
		uniform sampler2D tex0;\n\
		\n\
    	void main(void) {\n\
        	gl_FragColor = vec4(pow(texture2D(tex0,tc).rgb/0.88,vec3(0.97,1.1,1.2)),1.);\n\
    	}",
		heightflow:
		"precision highp float;\n\
		varying vec2 tc;\n\
		uniform sampler2D tex0;\n\
		uniform sampler2D tex1;\n\
		uniform sampler2D tex2;\n\
		uniform float power;\n\
		uniform float offset;\n\
		uniform float bright;\n\
		uniform float dark;\n\
		uniform float warp;\n\
		uniform vec2 shift;\n\
		uniform vec4 LC;\n\
		uniform float mixin;\n\
		uniform float mixinsat;\n\
		uniform float aspect;\n\
		uniform float zoom;\n\
		const float pi=3.1415926;\n\
		const int samples = 12;\n\
		\n\
		void main()\n\
		{   \n\
    		vec4 a = texture2D(tex0, tc);\n\
			vec2 asp = vec2(aspect,1.);\n\
			vec2 mtc = (tc-0.5)/asp*zoom+0.5-vec2(0.,0.15);\n\
    		vec4 b = texture2D(tex1, mtc);\n\
    		vec4 c = texture2D(tex2, tc);\n\
			//vec4 LC2 = LC;\n\
			vec4 LC2 = (LC/(dot(LC,vec4(1.))))*0.9;\n\
			//vec4 LC2 = vec4(normalize(LC.rgb)*0.7,0.);\n\
    		b = mix(vec4(vec3(dot(b,LC2)),1.),b,mixinsat);\n\
    		vec2 accumxy = vec2(0.,0.);\n\
    		for(int i=0; i<samples; i++){\n\
        		vec2 tca = vec2(sin(float(i)*pi*2./float(samples)),cos(float(i)*pi*2./float(samples)));\n\
        		float tick = dot(texture2D(tex0, tc+tca*offset*asp),LC2)-0.5;\n\
        		tick += dot(texture2D(tex0, tc+tca*offset*2.*asp),LC2)-0.5;\n\
        		accumxy += tca * tick/2.;\n\
    		}\n\
			vec2 wrp = (c.gb-0.5)*smoothstep(0.4,0.26,length(tc-0.5))*0.005;\n\
			vec4 hit = texture2D(tex0, tc+accumxy*(power*mix(-1.,1.,smoothstep(0.6,0.3,c.r)))*asp+shift+wrp)*vec4(vec3(bright*(mix(0.998,1.003,smoothstep(0.4,0.7,c.r)))),1.)-vec4(vec3(dark),0.);\n\
			hit = clamp(mix(hit,b+vec4((c.rgb-0.5)*0.2,0.),mixin),vec4(0.),vec4(1.)); \n\
			gl_FragColor = vec4(hit.rgb,1.);\n\
		}",
		heightflow2:
		"precision highp float;\n\
		varying vec2 tc;\n\
		uniform sampler2D tex0;\n\
		uniform sampler2D tex1;\n\
		uniform float power;\n\
		uniform float offset;\n\
		uniform float bright;\n\
		uniform float dark;\n\
		uniform vec2 shift;\n\
		uniform vec4 LC;\n\
		uniform float mixin;\n\
		uniform float mixinsat;\n\
		uniform float aspect;\n\
		const float pi=3.1415926;\n\
		const int samples = 12;\n\
		\n\
		void main()\n\
		{   \n\
    		vec4 a = texture2D(tex0, tc);\n\
			vec2 asp = vec2(aspect,1.);\n\
    		vec4 b = texture2D(tex1, tc);\n\
    		vec2 accumxy = vec2(0.,0.);\n\
    		for(int i=0; i<samples; i++){\n\
        		vec2 tca = vec2(sin(float(i)*pi*2./float(samples)),cos(float(i)*pi*2./float(samples)));\n\
        		float tick = texture2D(tex0, tc+tca*offset*asp).r-0.5;\n\
        		tick += texture2D(tex0, tc+tca*offset*2.*asp).r-0.5;\n\
        		accumxy += tca * tick/2.;\n\
    		}\n\
			vec2 wrp = (b.bg-0.5)*-0.03;\n\
			vec4 hit = texture2D(tex0, tc+accumxy*(power*mix(-1.,1.,smoothstep(0.6,0.3,b.r)))*asp+shift+wrp)*vec4(vec3(bright),1.)-vec4(vec3(dark),0.);\n\
			hit = mix(hit,b,mixin);\n\
			gl_FragColor = vec4(hit.r,b.gb,1.);\n\
		}"
};

export default shades;
