import React, { useState, useEffect, useContext } from "react";
import { useContractRead } from "wagmi";
import { PxContext } from "../App";
import ShadowFlora from '../data/ShadowFlora.json';


const Mintcount = (props) => {
    const [count,setCount] = useState("?");
    const {address} = useContext(PxContext);

    const checkCount = (cont) => {
       try {
           if(props.contract){
               props.contract.lastMintedId().then(result=>{
                    setCount(parseInt(result));
                });
                console.log('updated count');
            }
            //else console.log('contract not ready yet');
        }
        catch(error) {console.log(error)}
    }
    const {data: mintcounter} = useContractRead({
        addressOrName: address,
        contractInterface: ShadowFlora.abi,
    },'lastMintedId');

    useEffect(()=>{
    },[props.contract]);

    return (               
        <h3>{`${mintcounter}/250 Minted`}</h3>
    );
};

export default Mintcount;

