import { ConnectButton } from '@rainbow-me/rainbowkit';
import { useContract } from 'wagmi';
import {useState,useEffect,useContext} from 'react';
import {ethers} from "ethers";
import Mintcount from './mintcount';
import { PxContext } from '../App';
import ShadowFlora from '../data/ShadowFlora.json'

export const RainbowButton = (props: Props) => {
    const {signer} = props;
    const {address} = useContext(PxContext);

    const [mintState,setMintState] = useState(false);
    const contract = useContract({
        addressOrName: address,
        contractInterface: ShadowFlora.abi,
        signerOrProvider: signer,
    });

    const checkMintState = async (c)=>{
      if(c) {
            await c.mintActive().then(result=> setMintState(result));
        }
      
    }
    const triggerBuy = async ()=>{
        let txn = await contract.buy({value: ethers.utils.parseEther('0.2')});
        console.log("Mining...please wait.")
        await txn.wait();           
        console.log(`Mined, see transaction: https://etherscan.io/tx/${txn.hash}`);
    }

    useEffect(()=>{
        checkMintState(contract);
    },[signer,contract]);
  return (
    <>
    <div className="mint-container">
    <ConnectButton />
         { mintState && signer ? 
            (
            <button onClick={triggerBuy} className="cta-button mint-button">
                Mint (0.2 ETH)
            </button>
            ) :( signer ? (
                <button className="cta-button no-mint-button" disabled={!mintState}>
                    Mint Not Yet Active
                </button>
            ) : (
              <button className="cta-button no-mint-button" disabled={true}>
                    Not Connected
                </button>
            )
            )
        }
        <Mintcount contract={contract} />        
        </div>
    </>
  );
};