import { RainbowButton } from "./rainbowbutton";
import {useSigner} from 'wagmi';
import {useState} from 'react';

export function RainbowSign( props ){
    const [signReady,setSignReady] = useState(false);
    const { data: signerData } = useSigner({
        onError(e) {
          console.log('Error', e)
        },
        onSuccess(e){
            setSignReady(true);
        },
      })
    return(
        <RainbowButton signer={signerData} ready={signReady} />
    )
}