import {React, useEffect} from 'react';
import { useParams } from "react-router-dom";
import {initGL,initSlabs,initImages,removeGL} from '../scripts/pxGLstart';

const Seed = (props)=> {
  let params = useParams();
  let tokenseed = params.seed;
  const seed = parseInt(tokenseed.slice(0, 16), 16);
  useEffect(()=>{
    initGL(seed);
    initSlabs();
    initImages();
    //animate();
    return () => {
      removeGL();
    };
  },[seed]);
  
  return (
    <main style={{ 
      position: 'absolute', 
      top: 0, left: 0, width: '100vw', height: '100vh',
      display: 'flex', alignItems: 'center', justifyContent: 'center',
      backgroundColor: '#000'  }}>
      <canvas id="c" />
    </main>
  );
};

  export default Seed;