import {useRef} from "react";
import {Link} from "react-router-dom";
import useFullscreenStatus from "../hooks/useFullscreenStatus";

export default function FullView({ children, backgroundColor }) {
  const maximizableElement = useRef(null);
  let isFullscreen, setIsFullscreen;
  let errorMessage;

  try {
    [isFullscreen, setIsFullscreen] = useFullscreenStatus(maximizableElement);
  } catch (e) {
    errorMessage = "Fullscreen not supported";
    isFullscreen = false;
    setIsFullscreen = undefined;
  }

  const handleExitFullscreen = () => document.exitFullscreen();

  return (
    <div
      ref={maximizableElement}
      className={`fullscreen-container ${
        isFullscreen ? "fullscreen" : "default"
      }`}
      style={{ backgroundColor: isFullscreen ? backgroundColor : null }}
    >
      <div className="fullscreen-content">{children}</div>
      <div className="fullscreen-actions">
        <div className="fullscreen-button">
          {errorMessage ? (
            <button
              onClick={() =>
                alert(
                  "Fullscreen is unsupported by this browser."
                )
              }
            >
              {errorMessage}
            </button>
          ) : isFullscreen ? (
            <button onClick={handleExitFullscreen}><Minimize /></button>
          ) : (
            <button onClick={setIsFullscreen}><Maximize /></button>
          )}
        </div>
        <div className="fullscreen-nav"><Link to="/">✾</Link></div>
      </div>
    </div>
  );
}

export function Maximize(){
    return (
        <svg viewBox="0, 0, 100, 100">
            <g id="Layer_1">
                <g>
                <path d="M28.095,90.5 C29.174,90.5 30.05,89.625 30.05,88.545 C30.05,87.465 29.174,86.59 28.095,86.59 L17.175,86.59 L29.534,74.23 C30.582,73.182 30.813,71.714 30.05,70.95 C29.286,70.187 27.818,70.418 26.77,71.466 L14.41,83.825 L14.41,72.905 C14.41,71.826 13.535,70.95 12.455,70.95 C11.375,70.95 10.5,71.826 10.5,72.905 L10.5,88.545 C10.53,88.864 10.497,88.76 10.533,88.871 C10.545,88.993 10.531,88.918 10.602,89.088 C10.738,89.51 10.644,89.359 10.809,89.578 C10.849,89.673 10.819,89.623 10.915,89.719 L11.073,89.927 L11.281,90.085 C11.372,90.176 11.327,90.151 11.402,90.183 C11.635,90.371 11.477,90.271 11.919,90.4 C12.183,90.512 12.01,90.455 12.455,90.5 L28.095,90.5 z" fill="#FFFFFF"/>
                <path d="M28.095,90.5 C29.174,90.5 30.05,89.625 30.05,88.545 C30.05,87.465 29.174,86.59 28.095,86.59 L17.175,86.59 L29.534,74.23 C30.582,73.182 30.813,71.714 30.05,70.95 C29.286,70.187 27.818,70.418 26.77,71.466 L14.41,83.825 L14.41,72.905 C14.41,71.826 13.535,70.95 12.455,70.95 C11.375,70.95 10.5,71.826 10.5,72.905 L10.5,88.545 C10.53,88.864 10.497,88.76 10.533,88.871 C10.545,88.993 10.531,88.918 10.602,89.088 C10.738,89.51 10.644,89.359 10.809,89.578 C10.849,89.673 10.819,89.623 10.915,89.719 L11.073,89.927 L11.281,90.085 C11.372,90.176 11.327,90.151 11.402,90.183 C11.635,90.371 11.477,90.271 11.919,90.4 C12.183,90.512 12.01,90.455 12.455,90.5 L28.095,90.5 z" fillOpacity="0" stroke="#000000" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
                <g>
                <path d="M28.095,10.5 C29.174,10.5 30.05,11.375 30.05,12.455 C30.05,13.535 29.174,14.41 28.095,14.41 L17.175,14.41 L29.534,26.77 C30.582,27.818 30.813,29.286 30.05,30.05 C29.286,30.813 27.818,30.582 26.77,29.534 L14.41,17.175 L14.41,28.095 C14.41,29.174 13.535,30.05 12.455,30.05 C11.375,30.05 10.5,29.174 10.5,28.095 L10.5,12.455 C10.53,12.136 10.497,12.24 10.533,12.129 C10.545,12.007 10.531,12.082 10.602,11.912 C10.738,11.49 10.644,11.641 10.809,11.422 C10.849,11.327 10.819,11.377 10.915,11.281 L11.073,11.073 L11.281,10.915 C11.372,10.824 11.327,10.849 11.402,10.817 C11.635,10.629 11.477,10.729 11.919,10.6 C12.183,10.488 12.01,10.545 12.455,10.5 L28.095,10.5 z" fill="#FFFFFF"/>
                <path d="M28.095,10.5 C29.174,10.5 30.05,11.375 30.05,12.455 C30.05,13.535 29.174,14.41 28.095,14.41 L17.175,14.41 L29.534,26.77 C30.582,27.818 30.813,29.286 30.05,30.05 C29.286,30.813 27.818,30.582 26.77,29.534 L14.41,17.175 L14.41,28.095 C14.41,29.174 13.535,30.05 12.455,30.05 C11.375,30.05 10.5,29.174 10.5,28.095 L10.5,12.455 C10.53,12.136 10.497,12.24 10.533,12.129 C10.545,12.007 10.531,12.082 10.602,11.912 C10.738,11.49 10.644,11.641 10.809,11.422 C10.849,11.327 10.819,11.377 10.915,11.281 L11.073,11.073 L11.281,10.915 C11.372,10.824 11.327,10.849 11.402,10.817 C11.635,10.629 11.477,10.729 11.919,10.6 C12.183,10.488 12.01,10.545 12.455,10.5 L28.095,10.5 z" fillOpacity="0" stroke="#000000" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
                <g>
                <path d="M72.905,10.5 C71.826,10.5 70.95,11.375 70.95,12.455 C70.95,13.535 71.826,14.41 72.905,14.41 L83.825,14.41 L71.466,26.77 C70.418,27.818 70.187,29.286 70.95,30.05 C71.714,30.813 73.182,30.582 74.23,29.534 L86.59,17.175 L86.59,28.095 C86.59,29.174 87.465,30.05 88.545,30.05 C89.625,30.05 90.5,29.174 90.5,28.095 L90.5,12.455 C90.47,12.136 90.503,12.24 90.467,12.129 C90.455,12.007 90.469,12.082 90.398,11.912 C90.262,11.49 90.356,11.641 90.191,11.422 C90.151,11.327 90.181,11.377 90.085,11.281 L89.927,11.073 L89.719,10.915 C89.628,10.824 89.673,10.849 89.598,10.817 C89.365,10.629 89.523,10.729 89.081,10.6 C88.817,10.488 88.99,10.545 88.545,10.5 L72.905,10.5 z" fill="#FFFFFF"/>
                <path d="M72.905,10.5 C71.826,10.5 70.95,11.375 70.95,12.455 C70.95,13.535 71.826,14.41 72.905,14.41 L83.825,14.41 L71.466,26.77 C70.418,27.818 70.187,29.286 70.95,30.05 C71.714,30.813 73.182,30.582 74.23,29.534 L86.59,17.175 L86.59,28.095 C86.59,29.174 87.465,30.05 88.545,30.05 C89.625,30.05 90.5,29.174 90.5,28.095 L90.5,12.455 C90.47,12.136 90.503,12.24 90.467,12.129 C90.455,12.007 90.469,12.082 90.398,11.912 C90.262,11.49 90.356,11.641 90.191,11.422 C90.151,11.327 90.181,11.377 90.085,11.281 L89.927,11.073 L89.719,10.915 C89.628,10.824 89.673,10.849 89.598,10.817 C89.365,10.629 89.523,10.729 89.081,10.6 C88.817,10.488 88.99,10.545 88.545,10.5 L72.905,10.5 z" fillOpacity="0" stroke="#000000" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
                <g>
                <path d="M72.905,90.5 C71.826,90.5 70.95,89.625 70.95,88.545 C70.95,87.465 71.826,86.59 72.905,86.59 L83.825,86.59 L71.466,74.23 C70.418,73.182 70.187,71.714 70.95,70.95 C71.714,70.187 73.182,70.418 74.23,71.466 L86.59,83.825 L86.59,72.905 C86.59,71.826 87.465,70.95 88.545,70.95 C89.625,70.95 90.5,71.826 90.5,72.905 L90.5,88.545 C90.47,88.864 90.503,88.76 90.467,88.871 C90.455,88.993 90.469,88.918 90.398,89.088 C90.262,89.51 90.356,89.359 90.191,89.578 C90.151,89.673 90.181,89.623 90.085,89.719 L89.927,89.927 L89.719,90.085 C89.628,90.176 89.673,90.151 89.598,90.183 C89.365,90.371 89.523,90.271 89.081,90.4 C88.817,90.512 88.99,90.455 88.545,90.5 L72.905,90.5 z" fill="#FFFFFF"/>
                <path d="M72.905,90.5 C71.826,90.5 70.95,89.625 70.95,88.545 C70.95,87.465 71.826,86.59 72.905,86.59 L83.825,86.59 L71.466,74.23 C70.418,73.182 70.187,71.714 70.95,70.95 C71.714,70.187 73.182,70.418 74.23,71.466 L86.59,83.825 L86.59,72.905 C86.59,71.826 87.465,70.95 88.545,70.95 C89.625,70.95 90.5,71.826 90.5,72.905 L90.5,88.545 C90.47,88.864 90.503,88.76 90.467,88.871 C90.455,88.993 90.469,88.918 90.398,89.088 C90.262,89.51 90.356,89.359 90.191,89.578 C90.151,89.673 90.181,89.623 90.085,89.719 L89.927,89.927 L89.719,90.085 C89.628,90.176 89.673,90.151 89.598,90.183 C89.365,90.371 89.523,90.271 89.081,90.4 C88.817,90.512 88.99,90.455 88.545,90.5 L72.905,90.5 z" fillOpacity="0" stroke="#000000" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
                <g>
                <path d="M49.655,35.723 C51.311,35.723 52.655,37.066 52.655,38.723 L52.655,47.723 L61.655,47.723 C63.311,47.723 64.655,49.066 64.655,50.723 C64.655,52.38 63.311,53.723 61.655,53.723 L52.655,53.723 L52.655,62.723 C52.655,64.38 51.311,65.723 49.655,65.723 C47.998,65.723 46.655,64.38 46.655,62.723 L46.655,53.723 L37.655,53.723 C35.998,53.723 34.655,52.38 34.655,50.723 C34.655,49.066 35.998,47.723 37.655,47.723 L46.655,47.723 L46.655,38.723 C46.655,37.066 47.998,35.723 49.655,35.723 z" fill="#FFFFFF"/>
                <path d="M49.655,35.723 C51.311,35.723 52.655,37.066 52.655,38.723 L52.655,47.723 L61.655,47.723 C63.311,47.723 64.655,49.066 64.655,50.723 C64.655,52.38 63.311,53.723 61.655,53.723 L52.655,53.723 L52.655,62.723 C52.655,64.38 51.311,65.723 49.655,65.723 C47.998,65.723 46.655,64.38 46.655,62.723 L46.655,53.723 L37.655,53.723 C35.998,53.723 34.655,52.38 34.655,50.723 C34.655,49.066 35.998,47.723 37.655,47.723 L46.655,47.723 L46.655,38.723 C46.655,37.066 47.998,35.723 49.655,35.723 z" fillOpacity="0" stroke="#000000" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
            </g>
        </svg>
    )
}

export function Minimize(){
    return (
        <svg viewBox="0, 0, 100, 100">
            <g id="Layer_1">
                <g>
                <path d="M12.905,70.5 C11.826,70.5 10.95,71.375 10.95,72.455 C10.95,73.535 11.826,74.41 12.905,74.41 L23.825,74.41 L11.466,86.77 C10.418,87.818 10.187,89.286 10.95,90.05 C11.714,90.813 13.182,90.582 14.23,89.534 L26.59,77.175 L26.59,88.095 C26.59,89.174 27.465,90.05 28.545,90.05 C29.625,90.05 30.5,89.174 30.5,88.095 L30.5,72.455 C30.47,72.136 30.503,72.24 30.467,72.129 C30.455,72.007 30.469,72.082 30.398,71.912 C30.262,71.49 30.356,71.641 30.191,71.422 C30.151,71.327 30.181,71.377 30.085,71.281 L29.927,71.073 L29.719,70.915 C29.628,70.824 29.673,70.849 29.598,70.817 C29.365,70.629 29.523,70.729 29.081,70.6 C28.817,70.488 28.99,70.545 28.545,70.5 L12.905,70.5 z" fill="#FFFFFF"/>
                <path d="M12.905,70.5 C11.826,70.5 10.95,71.375 10.95,72.455 C10.95,73.535 11.826,74.41 12.905,74.41 L23.825,74.41 L11.466,86.77 C10.418,87.818 10.187,89.286 10.95,90.05 C11.714,90.813 13.182,90.582 14.23,89.534 L26.59,77.175 L26.59,88.095 C26.59,89.174 27.465,90.05 28.545,90.05 C29.625,90.05 30.5,89.174 30.5,88.095 L30.5,72.455 C30.47,72.136 30.503,72.24 30.467,72.129 C30.455,72.007 30.469,72.082 30.398,71.912 C30.262,71.49 30.356,71.641 30.191,71.422 C30.151,71.327 30.181,71.377 30.085,71.281 L29.927,71.073 L29.719,70.915 C29.628,70.824 29.673,70.849 29.598,70.817 C29.365,70.629 29.523,70.729 29.081,70.6 C28.817,70.488 28.99,70.545 28.545,70.5 L12.905,70.5 z" fillOpacity="0" stroke="#000000" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
                <g>
                <path d="M12.905,30.5 C11.826,30.5 10.95,29.625 10.95,28.545 C10.95,27.465 11.826,26.59 12.905,26.59 L23.825,26.59 L11.466,14.23 C10.418,13.182 10.187,11.714 10.95,10.95 C11.714,10.187 13.182,10.418 14.23,11.466 L26.59,23.825 L26.59,12.905 C26.59,11.826 27.465,10.95 28.545,10.95 C29.625,10.95 30.5,11.826 30.5,12.905 L30.5,28.545 C30.47,28.864 30.503,28.76 30.467,28.871 C30.455,28.993 30.469,28.918 30.398,29.088 C30.262,29.51 30.356,29.359 30.191,29.578 C30.151,29.673 30.181,29.623 30.085,29.719 L29.927,29.927 L29.719,30.085 C29.628,30.176 29.673,30.151 29.598,30.183 C29.365,30.371 29.523,30.271 29.081,30.4 C28.817,30.512 28.99,30.455 28.545,30.5 L12.905,30.5 z" fill="#FFFFFF"/>
                <path d="M12.905,30.5 C11.826,30.5 10.95,29.625 10.95,28.545 C10.95,27.465 11.826,26.59 12.905,26.59 L23.825,26.59 L11.466,14.23 C10.418,13.182 10.187,11.714 10.95,10.95 C11.714,10.187 13.182,10.418 14.23,11.466 L26.59,23.825 L26.59,12.905 C26.59,11.826 27.465,10.95 28.545,10.95 C29.625,10.95 30.5,11.826 30.5,12.905 L30.5,28.545 C30.47,28.864 30.503,28.76 30.467,28.871 C30.455,28.993 30.469,28.918 30.398,29.088 C30.262,29.51 30.356,29.359 30.191,29.578 C30.151,29.673 30.181,29.623 30.085,29.719 L29.927,29.927 L29.719,30.085 C29.628,30.176 29.673,30.151 29.598,30.183 C29.365,30.371 29.523,30.271 29.081,30.4 C28.817,30.512 28.99,30.455 28.545,30.5 L12.905,30.5 z" fillOpacity="0" stroke="#000000" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
                <g>
                <path d="M88.095,30.5 C89.174,30.5 90.05,29.625 90.05,28.545 C90.05,27.465 89.174,26.59 88.095,26.59 L77.175,26.59 L89.534,14.23 C90.582,13.182 90.813,11.714 90.05,10.95 C89.286,10.187 87.818,10.418 86.77,11.466 L74.41,23.825 L74.41,12.905 C74.41,11.826 73.535,10.95 72.455,10.95 C71.375,10.95 70.5,11.826 70.5,12.905 L70.5,28.545 C70.53,28.864 70.497,28.76 70.533,28.871 C70.545,28.993 70.531,28.918 70.602,29.088 C70.738,29.51 70.644,29.359 70.809,29.578 C70.849,29.673 70.819,29.623 70.915,29.719 L71.073,29.927 L71.281,30.085 C71.372,30.176 71.327,30.151 71.402,30.183 C71.635,30.371 71.477,30.271 71.919,30.4 C72.183,30.512 72.01,30.455 72.455,30.5 L88.095,30.5 z" fill="#FFFFFF"/>
                <path d="M88.095,30.5 C89.174,30.5 90.05,29.625 90.05,28.545 C90.05,27.465 89.174,26.59 88.095,26.59 L77.175,26.59 L89.534,14.23 C90.582,13.182 90.813,11.714 90.05,10.95 C89.286,10.187 87.818,10.418 86.77,11.466 L74.41,23.825 L74.41,12.905 C74.41,11.826 73.535,10.95 72.455,10.95 C71.375,10.95 70.5,11.826 70.5,12.905 L70.5,28.545 C70.53,28.864 70.497,28.76 70.533,28.871 C70.545,28.993 70.531,28.918 70.602,29.088 C70.738,29.51 70.644,29.359 70.809,29.578 C70.849,29.673 70.819,29.623 70.915,29.719 L71.073,29.927 L71.281,30.085 C71.372,30.176 71.327,30.151 71.402,30.183 C71.635,30.371 71.477,30.271 71.919,30.4 C72.183,30.512 72.01,30.455 72.455,30.5 L88.095,30.5 z" fillOpacity="0" stroke="#000000" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
                <g>
                <path d="M88.095,70.5 C89.174,70.5 90.05,71.375 90.05,72.455 C90.05,73.535 89.174,74.41 88.095,74.41 L77.175,74.41 L89.534,86.77 C90.582,87.818 90.813,89.286 90.05,90.05 C89.286,90.813 87.818,90.582 86.77,89.534 L74.41,77.175 L74.41,88.095 C74.41,89.174 73.535,90.05 72.455,90.05 C71.375,90.05 70.5,89.174 70.5,88.095 L70.5,72.455 C70.53,72.136 70.497,72.24 70.533,72.129 C70.545,72.007 70.531,72.082 70.602,71.912 C70.738,71.49 70.644,71.641 70.809,71.422 C70.849,71.327 70.819,71.377 70.915,71.281 L71.073,71.073 L71.281,70.915 C71.372,70.824 71.327,70.849 71.402,70.817 C71.635,70.629 71.477,70.729 71.919,70.6 C72.183,70.488 72.01,70.545 72.455,70.5 L88.095,70.5 z" fill="#FFFFFF"/>
                <path d="M88.095,70.5 C89.174,70.5 90.05,71.375 90.05,72.455 C90.05,73.535 89.174,74.41 88.095,74.41 L77.175,74.41 L89.534,86.77 C90.582,87.818 90.813,89.286 90.05,90.05 C89.286,90.813 87.818,90.582 86.77,89.534 L74.41,77.175 L74.41,88.095 C74.41,89.174 73.535,90.05 72.455,90.05 C71.375,90.05 70.5,89.174 70.5,88.095 L70.5,72.455 C70.53,72.136 70.497,72.24 70.533,72.129 C70.545,72.007 70.531,72.082 70.602,71.912 C70.738,71.49 70.644,71.641 70.809,71.422 C70.849,71.327 70.819,71.377 70.915,71.281 L71.073,71.073 L71.281,70.915 C71.372,70.824 71.327,70.849 71.402,70.817 C71.635,70.629 71.477,70.729 71.919,70.6 C72.183,70.488 72.01,70.545 72.455,70.5 L88.095,70.5 z" fillOpacity="0" stroke="#000000" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
                <g>
                <path d="M49.655,35.723 C51.311,35.723 52.655,37.066 52.655,38.723 L52.655,47.723 L61.655,47.723 C63.311,47.723 64.655,49.066 64.655,50.723 C64.655,52.38 63.311,53.723 61.655,53.723 L52.655,53.723 L52.655,62.723 C52.655,64.38 51.311,65.723 49.655,65.723 C47.998,65.723 46.655,64.38 46.655,62.723 L46.655,53.723 L37.655,53.723 C35.998,53.723 34.655,52.38 34.655,50.723 C34.655,49.066 35.998,47.723 37.655,47.723 L46.655,47.723 L46.655,38.723 C46.655,37.066 47.998,35.723 49.655,35.723 z" fill="#FFFFFF"/>
                <path d="M49.655,35.723 C51.311,35.723 52.655,37.066 52.655,38.723 L52.655,47.723 L61.655,47.723 C63.311,47.723 64.655,49.066 64.655,50.723 C64.655,52.38 63.311,53.723 61.655,53.723 L52.655,53.723 L52.655,62.723 C52.655,64.38 51.311,65.723 49.655,65.723 C47.998,65.723 46.655,64.38 46.655,62.723 L46.655,53.723 L37.655,53.723 C35.998,53.723 34.655,52.38 34.655,50.723 C34.655,49.066 35.998,47.723 37.655,47.723 L46.655,47.723 L46.655,38.723 C46.655,37.066 47.998,35.723 49.655,35.723 z" fillOpacity="0" stroke="#000000" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
            </g>
        </svg>
    )
}